export const getAuth0Config = () => {
  const { REACT_APP_AUTH0_DOMAIN, REACT_APP_AUTH0_CLIENT_ID } = process.env;

  if (!REACT_APP_AUTH0_DOMAIN || !REACT_APP_AUTH0_CLIENT_ID)
    throw new Error('Unable to load auth0 configuration');

  return {
    domain: REACT_APP_AUTH0_DOMAIN as string,
    clientId: REACT_APP_AUTH0_CLIENT_ID as string,
  };
};

/* eslint-disable complexity */
import { Route, Routes } from 'react-router-dom';
import React, { useState } from 'react';
import { createBrowserHistory } from 'history';
import { CustomHistoryRouter, AuthProvider } from '../components';
import {
  EVENT_RESPONSE,
  EVENT_TYPE,
  FRAGMENT_REGEX,
  IUX_URL,
  REQUEST_TYPE,
  ROUTE_MAPPINGS,
} from './App.constants';
import './App.css';

window.wspHistory = createBrowserHistory();

const { REACT_APP_IUX_HOST = '' } = process.env;

const App = () => {
  const [randomDigit, setRandomDigit] = useState('');
  const IUX_MAIN_PAGE = IUX_URL(REACT_APP_IUX_HOST);

  const IUX_MAPPINGS = new Map(
    Object.entries(ROUTE_MAPPINGS).map(([k, v]) => [v, k]),
  );

  const onWatchEventHandler = (
    event: MessageEvent<{ type: string; message: string; fragment?: string }>,
  ) => {
    const {
      data: { fragment = '' },
    } = event;

    if (!validateMessage(event)) return;

    if (isRequestToken(event)) {
      const tokenResponse = (token: string) =>
        postTokenResponseMessage(event, token);

      window.wspAuth.getAccessToken().then(tokenResponse);
    } else if (isRequestNavigation(event)) {
      const route = IUX_MAPPINGS.get(fragment.replace(FRAGMENT_REGEX, ''));

      if (route) {
        const match = fragment.match(FRAGMENT_REGEX);

        window.wspHistory.push(route, { randomDigit: match ? match[1] : '' });
        setRandomDigit(match ? match[1] : '');
      }
    }
  };

  React.useEffect(() => {
    window.addEventListener('message', onWatchEventHandler);
    return () => window.removeEventListener('message', onWatchEventHandler);
  }, [onWatchEventHandler]);

  const generateIuxFrame = (entry: [string, string]) => {
    const [containerPath, iuxPath] = entry;
    let fullIuxPath = IUX_MAIN_PAGE + iuxPath;

    if (randomDigit) {
      const alreadyHasQueryString = iuxPath.match(/\?/);
      fullIuxPath += (alreadyHasQueryString ? '&' : '?') + 'rd=' + randomDigit;
    }

    return (
      <Route
        key={containerPath}
        path={containerPath}
        element={
          <iframe title="iux-iframe" id="iux" src={fullIuxPath}></iframe>
        }
      />
    );
  };

  return (
    <CustomHistoryRouter history={window.wspHistory}>
      <AuthProvider>
        <Routes>{Object.entries(ROUTE_MAPPINGS).map(generateIuxFrame)}</Routes>
      </AuthProvider>
    </CustomHistoryRouter>
  );
};

const validateMessage = (
  event: MessageEvent<{ type: string; message: string; fragment?: string }>,
) => {
  const {
    data: { type, message },
  } = event;

  return (
    typeof event.data == 'object' && type == EVENT_TYPE.WSP_ROUTER && message
  );
};

const postTokenResponseMessage = (
  event: MessageEvent<{ type: string; message: string; fragment?: string }>,
  token: string,
) => {
  const { source, origin } = event;

  const responseFrame = source as MessageEventSource;
  const responseDomain = origin || '*';

  responseFrame.postMessage(
    {
      type: EVENT_TYPE.WSP_ROUTER,
      message: EVENT_RESPONSE.TOKEN_RESPONSE,
      token,
    },
    { targetOrigin: responseDomain },
  );
};

const isRequestToken = (
  event: MessageEvent<{ type: string; message: string; fragment?: string }>,
) => {
  const {
    data: { message: eventMessage },
    source,
  } = event;

  return eventMessage === REQUEST_TYPE.TOKEN_REQUEST && source != null;
};

const isRequestNavigation = (
  event: MessageEvent<{ type: string; message: string; fragment?: string }>,
) => {
  const {
    data: { message: eventMessage, fragment },
    source,
  } = event;

  return (
    eventMessage === REQUEST_TYPE.IUX_NAVIGATION && source != null && fragment
  );
};

export default App;

import React from 'react';
import { useAuth, useUser } from '../hooks';
import { AuthProviderType } from '../AuthProvider.types';

export const AuthenticatedUser = ({ children }: AuthProviderType) => {
  const auth = useAuth();
  const user = useUser();

  React.useEffect(() => {
    window.wspAuth = auth;
    window.wspUser = user;
  }, [auth, user]);

  const { isAuthenticated, isLoading, error } = auth;

  if (error) {
    return <p>Oops! Something went wrong!</p>;
  }

  if (isLoading || !isAuthenticated) {
    return <div>Loading...</div>;
  }

  return <>{children}</>;
};
